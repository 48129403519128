#stars {
  animation: animStar 50s linear infinite;
}

#stars:after {
  content: ' ';
  position: absolute;
  top: -2000px;
  width: 1px;
  height: 1px;
  background: transparent;
}

#stars2 {
  animation: animStar 100s linear infinite;
}

#stars2:after {
  content: ' ';
  position: absolute;
  top: -2000px;
  width: 2px;
  height: 2px;
  background: transparent;
}

#stars3 {
  animation: animStar 150s linear infinite;
}
#stars3:after {
  content: ' ';
  position: absolute;
  top: -2000px;
  width: 3px;
  height: 3px;
  background: transparent;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(2000px);
  }
}
