#rocket {
  animation: rocketFly 1s ease-in-out infinite;
}

#astronaut {
  animation: astronautFly 2.5s ease-in-out infinite;
}

@keyframes astronautFly {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@media (max-width: 600px) {
  @keyframes astronautFly {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-10px);
    }

    100% {
      transform: translateY(0);
    }
  }
}

@keyframes rocketFly {
  0% {
    transform: translateY(0) scale(0.5) rotate(-45deg);
  }

  50% {
    transform: translateY(-10px) scale(0.5) rotate(-45deg);
  }

  100% {
    transform: translateY(0) scale(0.5) rotate(-45deg);
  }
}

@media (max-width: 600px) {
  @keyframes rocketFly {
    0% {
      transform: translateY(0) scale(0.3) rotate(-45deg);
    }

    50% {
      transform: translateY(-10px) scale(0.3) rotate(-45deg);
    }

    100% {
      transform: translateY(0) scale(0.3) rotate(-45deg);
    }
  }
}
